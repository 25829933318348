import React from "react"
import Helmet from "react-helmet"

import {
  blogTitle,
  blogUrl,
  blogImageUrl
} from "../../gatsby-config"

const Ogp = props => {

  return (
    <Helmet>
      <html lang="ja" />
      <title>{props.title}</title>
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      <meta property="og:url" content={blogUrl} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={blogTitle} />
      <meta property="og:image" content={props.image || blogImageUrl} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@choclucy_" />
    </Helmet>
  )
}

export default Ogp

